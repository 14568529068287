import { createDynamicFlag } from '../model/dynamic.factory';

const $$testDepositFeatureFlag = createDynamicFlag(
  'KUPAY_1738_MERCHANT_TEST_DEPOSIT'
);

const $$massPayoutsFeatureFlag = createDynamicFlag('FE_MERCHANT_MASS_PAYOUTS');

const $$usdtForStatementReportFeatureFlag = createDynamicFlag(
  'KUPAY_2915_FE_MERCHANT_USDT_FOR_STATEMENT_REPORT'
);

const $$dashboardImprovementsFeatureFlag = createDynamicFlag(
  'KUPAY_2932_FE_MERCHANT_DASHBOARD_IMPROVEMENTS'
);

export {
  $$dashboardImprovementsFeatureFlag,
  $$massPayoutsFeatureFlag,
  $$testDepositFeatureFlag,
  $$usdtForStatementReportFeatureFlag,
};
