import type { Numeric } from 'decimal.js-light';
import Decimal from 'decimal.js-light';

import type { CoreAsset } from '@kuna-pay/core/entities/asset';

import type {
  CompanyAsset,
  Equivalent,
  PublicAssetOutput,
} from '@kuna-pay/merchant/generated/graphql';

import type { ExchangeRates } from '../types';

class ExchangeRateConverter {
  public static convertAssetByExchangeRate = (args: {
    amount: Numeric;
    rate: Equivalent;
  }) => {
    const amountAsDecimal = new Decimal(args.amount);

    return amountAsDecimal.mul(args.rate.amount);
  };

  public static getExchangeRate = (args: {
    fromAssetCode: PublicAssetOutput['code'];
    toAssetCode: PublicAssetOutput['code'];
    ratesMap: ExchangeRates;
  }) => {
    const ratesForAsset = args.ratesMap[args.fromAssetCode];

    if (!ratesForAsset) {
      return null;
    }

    const rateEquivalent = ratesForAsset.equivalent[args.toAssetCode];

    return rateEquivalent ?? null;
  };

  public static findEquivalentBalance = (args: {
    assetCode: CoreAsset['code'];
    amount: CompanyAsset['balance'];
    refCurrencyAssetCode: CoreAsset['code'];
    rates: ExchangeRates;
  }): Decimal | null => {
    const rate = ExchangeRateConverter.getExchangeRate({
      fromAssetCode: args.assetCode,
      toAssetCode: args.refCurrencyAssetCode,
      ratesMap: args.rates,
    });

    if (!rate) {
      return null;
    }

    return ExchangeRateConverter.convertAssetByExchangeRate({
      amount: args.amount,
      rate,
    });
  };
}

export { ExchangeRateConverter };
